import { useEffect, useState } from "react"
import { Badge } from "../ui/badge"
import APIServices from "../../app/services/APIService"

export default function BlogArticle(props:any) {
  const [image, setImage] = useState(Object)

  const returnExcerpt = (text:string) => {
    let findIndex = text?.indexOf('<a class="more-link"')
    return text?.slice(0, findIndex) + `<a style="text-decoration:none;color:#639C6E" href="/${props.post?.id}/${props.post.slug}">lire la suite</a>`
  } 

  useEffect(() => {
    const getImage = async () => {
      if (props.post.featured_media) {
        const respImage = await APIServices.getById('media', props.post?.featured_media)
        setImage(respImage.media_details ? respImage.media_details.sizes : respImage.guid.rendered)
      }
    }
    getImage()
  }, [props.post])

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-4">
        <img
          src={image?.medium ? image?.medium?.source_url : image?.full?.source_url}
          alt="Place de marché"
          className="rounded-xl h-[180px] md:h-[250px] object-cover w-full"
        />
      </div>

      <div className="col-span-12 md:col-span-8">
        <div>
          {props.post.categories.map((badge:string, index:number) => (
            <Badge key={index} variant="secondary" className="mr-1" catId={badge} />
          ))}
        </div>
        <a href={`/blog/${props.post?.id}/${props.post.slug}`}>
          <h3 className="text-3xl font-bold text-secondary-darker my-2" dangerouslySetInnerHTML={{__html: props.post?.title.rendered}} />
        </a>
        <p className="text-md" dangerouslySetInnerHTML={{__html: returnExcerpt(props.post?.excerpt.rendered)}} />
      </div>
    </div>
  )
}