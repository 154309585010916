import HomeSection from "../components/sections/home";
import FullCard from "../components/cards/fullCard";
import Partners from "../components/sections/partners";
import ColsExplain from "../components/sections/colsExplain";
import Mockup from "../assets/images/visuals/mockup.png";
import Delivery from "../assets/images/diagram/bike-delivery.png"
import Section from "../components/sections";

export default function Buyer() {
  return (
    <div>
      <div className="overflow-y-scroll h-screen flex-grow z-0">
        <div className="snap-always snap-center" id="page1">
          <HomeSection color="primary" buyer />
        </div>
        <div className="" id="page2">
          <Section
            id="1" 
            color="bg-secondary-lighter"
            textColor="text-secondary-dark"
            imgUrl={Mockup}
            texts={{
              title: "Passez commande en un rien de temps",
              subTitle: "Commandez sur nos regroupements disponibles autour de votre adresse et payez sur factures tous les mois."
            }}
            dynamic
          />
          <Section
            id="2" 
            color="bg-secondary-light" 
            textColor="text-secondary-foreground"
            imgUrl={Delivery}
            texts={{
              title: "Faites-vous livrer à votre adresse",
              subTitle: "En plus d’être efficace, nos livraisons sont éco-responsables grâce à l’engagement de notre prestataire."
            }}
            dynamic
          />
          <div className="container px-8 md:px-24 2xl:px-0 py-8 md:py-24">
            <FullCard buyer />
            <ColsExplain />
          </div>
          <Partners />
        </div>
      </div>
    </div>
    
  )
}