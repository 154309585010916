import { useEffect, useState } from "react"
import APIServices from "../../app/services/APIService"
import { useParams } from "react-router-dom"
import { Linkedin, PhoneOutgoing, Send } from "lucide-react"
import { Button } from "../ui/button"
import VCard from 'vcard-creator'

interface MemberProps {
  title: {
    rendered: string
  },
  acf: {
    poste: string,
    bio: string,
    contact: Contact[]
  },
  yoast_head_json: { og_image: [ { url: string } ]}
}

interface Contact {
  type: {
    value: string
  },
  content: string
}

export default function Member() {
  const [member, setMember] = useState({} as MemberProps)
  const { teamId } = useParams()

  useEffect(() => {
    const getTheMember = async () => {
      const response = await APIServices.getById('team', teamId)
      setMember(response)
    }
    getTheMember()
  }, [teamId])


  const addToContact = (vcfText:string) => {
    const element = document.createElement("a");
    const file = new Blob([vcfText], { type: "text/vcard;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.vcf";
    document.body.appendChild(element);
    element.click();
  };

  const CreateVCard = () => {
    // Define a new vCard
    const myVCard = new VCard()

    var contact = {
      name: member?.title?.rendered.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      phone: member?.acf?.contact.find(t => t.type.value === "Téléphone")?.content,
      email: member?.acf?.contact.find(t => t.type.value === "Email")?.content
    };

    myVCard
      // Add personal data
      .addName(member?.title?.rendered.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
      // Add work data
      .addCompany('Mullo')
      .addJobtitle(member?.acf?.poste)
      .addEmail(contact.email ? contact.email : '')
      .addPhoneNumber(contact.phone ? contact.phone : '', 'PREF;WORK')
      .addURL('https://mullo.fr')

    console.log(myVCard.toString())
    return myVCard.toString()
    
    // create a vcard file
    // var vcard = "BEGIN:VCARD\nVERSION:4.0\nFN:" + contact.name + "\nTEL;TYPE=work,voice:" + contact.phone + "\nEMAIL:" + contact.email + "\nEND:VCARD";

    // return vcard
    // var vCardsJS = require("vcards-js");

    // //create a new vCard
    // var vCard = vCardsJS();

    // //set properties
    // vCard.firstName = member?.title?.rendered.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    // // vCard.middleName = "J";
    // // vCard.lastName = "Nesser";
    // vCard.organization = "Mullo - Approvisionnement local";
    // vCard.workPhone = member?.acf?.contact.find(t => t.type.value === "Téléphone")?.content;
    // vCard.title = member?.acf?.poste;
    // vCard.url = "https://mullo.fr";
    // vCard.email = member?.acf?.contact.find(t => t.type.value === "Email")?.content
    // // vCard.note = "Notes on Eric";

    // //save to file
    // // vCard.saveToFile("./eric-nesser.vcf");

    // //get as formatted string
    // // console.log(vCard.getFormattedString());
    // return vCard.getFormattedString();
  };

  // Crete the Vcard to add contact
  // const addToContact = () => {
    // var contact = {
    //   name: member?.title?.rendered.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
    //   phone: member?.acf?.contact.find(t => t.type.value === "Téléphone")?.content,
    //   email: member?.acf?.contact.find(t => t.type.value === "Email")?.content
    // };
    // // create a vcard file
    // var vcard = "BEGIN:VCARD\nVERSION:4.0\nFN:" + contact.name + "\nTEL;TYPE=work,voice:" + contact.phone + "\nEMAIL:" + contact.email + "\nEND:VCARD";
  //   var blob = new Blob([vcard], { type: "text/vcard" });
  //   var url = URL.createObjectURL(blob);

  //   const newLink = document.createElement('a');
  //   newLink.download = contact.name + ".vcf";
  //   newLink.textContent = contact.name;
  //   newLink.href = url;

  //   newLink.click();
  // }
  

  return (
    <div className="mt-[-40px]">
      <div className="h-36 w-36 rounded-full overflow-hidden m-auto mb-3">
        <img src={member?.yoast_head_json?.og_image[0].url} className="h-full w-full object-cover" alt="avatar du membre" />
      </div>
      <h1 className="text-3xl text-bold text-center mb-2 text-[#273E2C]">{member?.title?.rendered}</h1>
      <h3 className="italic text-center mb-5 text-[#273E2C]">{member?.acf?.poste}</h3>

      {member?.acf?.contact.map((contact:Contact, index:number) => (
        <Sticker key={index} type={contact.type} info={contact.content} name={member?.title?.rendered} />
      ))}

      <Button onClick={() => addToContact(CreateVCard())} variant="secondary" className="m-auto w-full mt-6">
        Ajouter au contact
      </Button>
      
    </div>
  )
}


// Contact cards
const Sticker = (props:any) => {

  const getIcon = () => {
    const rs= {icon: <PhoneOutgoing />, link: ''}

    if (props.type.value === "Téléphone") {
      rs.icon = <PhoneOutgoing />
      rs.link = `tel:${props.info}`
    } else if (props.type.value === "Email") {
      rs.icon = <Send />
      rs.link = `mailto:${props.info}`
    } else if (props.type.value === "Linkedin") {
      rs.icon = <Linkedin />
      rs.link = `${props.info}`
    }

    return rs
  }

  return (
    <a href={getIcon().link} target="_blank" rel="noreferrer">
      <div className="bg-primary px-4 py-3 rounded-md mt-3 flex items-center hover:opacity-80 hover:cursor-pointer">
        {getIcon().icon}
        <div className="ml-4">
          <p className="text-sm italic">{props.type.value}</p>
          <p className="text-lg bold">{props.type.value === "Linkedin" ? `Le linkedin de ${props.name}` : props.info}</p>
        </div>
      </div>
    </a>
  )
};