import { Outlet } from "react-router-dom"
import logo from "../../assets/logos/logo.svg"

export default function Team() {

  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <div className="w-full sm:w-96 bg-primary pt-5 rounded-lg shadow-md flex flex-col justify-center items-center">
        <img
          src={logo}
          alt="Mullo"
          className="mb-14 w-60"
        />
        <div className="w-full sm:w-96 bg-[#F0F6F1] px-2 sm:px-8 pb-10 rounded-b-lg">
          <Outlet />
        </div>
      </div>

    </div>
  )
}