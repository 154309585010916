import { useEffect, useState } from "react"
import { Badge } from "../ui/badge"
import moment from "moment"
import APIServices from "../../app/services/APIService"

export default function InfrontArticle(props:any) {
  const [image, setImage] = useState(Object)

  useEffect(() => {
    const getImage = async () => {
      if (props.post.featured_media) {
        const respImage = await APIServices.getById('media', props.post?.featured_media)
        setImage(respImage.media_details ? respImage.media_details.sizes : respImage.guid.rendered)
      }
    }
    getImage()
  }, [props.post])
  
  return (
    <div 
      className={`bg-cover bg-center bg-no-repeat rounded-xl mb-12 min-h-96`}
      style={{backgroundImage:`url(${image?.medium ? image.medium.source_url : image.full?.source_url})`}}
    >
      <a 
        href={`/blog/${props.post?.id}/${props.post?.slug}`}
        className="block bg-slate-950 bg-opacity-40 px-8 py-16 rounded-xl min-h-96"
      >
        <div>
          {props.post.categories.map((badge:string, index:number) => (
            <Badge key={index} variant="secondary" className="mr-1" catId={badge} />
          ))}
        </div>
        <h1 className="text-4xl lg:text-7xl font-bold text-white my-4" dangerouslySetInnerHTML={{__html: props.post?.title.rendered}} />
        <div className="flex">
          <p className="text-sm text-white">{moment(props.post?.date).format('DD/MM/YYYY')}</p>
          {/* <p className="ml-4">{props.post?.yoast_head_json?.author}</p> */}
        </div>
      </a>
    </div>
  )
}