import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "./../../lib/utils"
import APIServices from "../../app/services/APIService"

const badgeVariants = cva(
  "inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {catId?: string}

function Badge({ className, variant, catId, ...props }: BadgeProps) {
  const [category, setCategory] = React.useState(Object)

  React.useEffect(() => {
    const getCategory = async () => {
      const response = await APIServices.getById('categories', catId)
      setCategory(response)
    }

    getCategory()
  }, [catId])

  return (
    <a href={`/blog/categories/${category?.id}/${category?.slug}`}>
      <div className={cn(badgeVariants({ variant }), className)} style={{backgroundColor: category?.acf?.color}} {...props}>{category?.name}</div>
    </a>
  )
}

export { Badge, badgeVariants }
