export default function Section(props:any) {

  return (
    <section className={`relative ${props.dynamic ? 'py-28' : 'min-h-screen'} flex justify-center items-center ${props.color}`}>
      <div className={`container px-8 md:px-24 2xl:px-0 mx-auto flex space-between flex-col-reverse md:flex-row  ${props.dynamic ? 'items-center' : 'items-center'}`}>
        <div className={`${props.full && 'lg:absolute lg:mb-40'} max-w-xl md:pr-20 flex-1`}>
          <h3 className={`text-4xl font-bold ${props.textColor}`}>
            {props.texts.title}
          </h3>
          <p className="text-xl mt-6">{props.texts.subTitle}</p>
        </div>

        <div className={`${props.full && 'lg:ml-60'} px-0 sm:px-20 md:px-0 flex-1 lg:flex-2 mb-10 md:mb-0`}>
          <img
            src={props.imgUrl}
            alt={props.texts.title}
          />
        </div>
      </div>
    </section>
  )
}