import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const API_RELATED = process.env.REACT_APP_API_RELATED

const getAll = (name, allHeader) => {
  const url = `${API_URL}/${name}`;
  return axios
    .get(url)
    .then(response => allHeader ? response : response.data)
    .catch(error => console.log(error));
}

const getById = (name, id) => {
  const url = `${API_URL}/${name}/${id}`;
  return axios
    .get(url)
    .then(response => response.data)
    .catch(error => console.log(error));
}

const getRelatedPosts = (id) => {
  const url = `${API_RELATED}/${id}`;
  return axios
    .get(url)
    .then(response => response.data)
    .catch(error => console.log(error));
}

const APIServices = {
  getAll,
  getById,
  getRelatedPosts
}

export default APIServices


