import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
// import Menu from '../../components/A-header/appBar'
// import Footer from '../../components/F-footer/footer'
// import { Box } from '@mui/material'

export default function Blog() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  

  return (
    <Outlet />
  )
}